/**
 * After update to using 'jsxImportSource', wasn't expected to need this reference line.
 * However, it seems to be needed for now because a few MUI components still need updating (e.g. Grow & Fade)
 * see: https://emotion.sh/docs/typescript
 */
/// <reference types="@emotion/react/types/css-prop" />
/// <reference types="@nx/react/typings/image" />

import type React from 'react';
import type { CurrencyCode, Feature, FeatureFlag, Network } from '@noah-labs/shared-schema-gql';

/**
 * Type Naming:
 *
 * - General Types: prefix with Tp, e.g. TpConfig
 * - Props: prefix with Pp, e.g. PpErrorPage
 * - State: prefix with St e.g. StAlerts
 * - Context: prefix with Cx e.g. CxAlertPush
 * - Router Params: prefix with Rp e.g. RpCurrency
 *
 */

/* eslint-disable @typescript-eslint/ban-types */

/**
 * Props With Children
 */
export type PpWC<P = {}> = P & {
  children: React.ReactNode;
};
/**
 * Props With Optional Children
 */
export type PpWOC<P = {}> = P & {
  children?: React.ReactNode;
};
/**
 * Props With Optional Component
 */
export type PpWComponent<P = {}> = P & {
  component?: React.ReactNode;
};
/* eslint-enable @typescript-eslint/ban-types */

export type TpHistoryListener = {
  listen(listener: () => void): () => void;
};

/**
 * Use undefined for a value that is not yet known
 * TextOrSkeleton will render loading state if value is null or undefined
 */
export type TpSkeletonText = string | number | null | undefined;

// Used for the share button when sharing files
export type TpFileShare = {
  fileType: string;
  filename: string;
  url: string;
};

export type TpShareProps = {
  file?: TpFileShare;
  onShare?: () => void;
  text: string | undefined;
  title?: string;
};

export type TpParseAddressData = {
  address: string;
  availableNetworks: Network[];
  isProd: boolean;
};

export type TpParseAddressDataWCc<Cc = CurrencyCode> = TpParseAddressData & {
  currencyCode: Cc;
};

/**
 * Supplement our Api FeatureFlags with some specific UI types
 */
export enum TpFFUI {
  // Controls access to the CkoBuy routes
  CkoBuyRoutes = 'CkoBuyRoutes',
  // Controls access to the CkoSell routes
  CkoSellRoutes = 'CkoSellRoutes',
  // Controls access to the Deposit routes
  DepositRoutes = 'DepositRoutes',
}
export type TpFeatureUI = Feature | TpFFUI;
export type TpFeatureFlagUI = Omit<FeatureFlag, 'Feature'> & {
  Feature: TpFeatureUI;
};
