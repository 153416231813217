import { createContext, useCallback, useMemo } from 'react';
import type { SafePin } from '@noah-labs/core-services';
import {
  decryptEnclaveResponse,
  decryptSecretValue,
  GenericPINError,
  getSecretValueRequest,
  HDWallet,
  SafeWrapper,
  setupWallet,
  validatePhrase,
} from '@noah-labs/core-services';
import { deriveNcwFromScw } from '@noah-labs/core-services/src/crypto/crypto';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { Feature } from '@noah-labs/shared-schema-gql';
import { isEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import type { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import type { UseMutateAsyncFunction } from 'react-query';
import { useQueryClient } from 'react-query';
import { isProd } from '../../../webConfigBrowser';
import { useFeature } from '../../user/hooks/useFeature';
import {
  handleDecryptError,
  signPayload,
  useDecryptSecretDocument,
  useGetSecretDocument,
  usePostSecretDocument,
  usePostSecretDocumentSupersede,
  useRevokeSecretDocument,
  useUnrevokeSecretDocument,
} from '../data';
import { useSigningJwt } from '../data/useSigningJwt';
import type {
  TpPayloadSignature,
  TpRevokeSecretDocumentRequest,
  TpSecretDocumentStatus,
  TpSignable,
  TpUnrevokeSecretDocumentRequest,
} from '../types';

export type CxSigning = {
  createNewWallet: (pin: SafePin) => Promise<SafeWrapper<string> | undefined>;
  createSignature: (pin: SafePin, payload: TpSignable) => Promise<TpPayloadSignature | undefined>;
  deriveScw: (pin: SafePin) => Promise<HDWallet>;
  isJwtFetched: boolean;
  recoverWallet: (pin: SafePin, mnemonic: SafeWrapper<string>) => Promise<void>;
  /** JWT is required. revoke bool flag true/false to revoke/unrevoke */
  revoke: UseMutateAsyncFunction<AxiosResponse, unknown, TpRevokeSecretDocumentRequest, unknown>;
  sdStatus: TpSecretDocumentStatus | undefined;
  /** Does not require JWT, can be called unauthed */
  unrevoke: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    TpUnrevokeSecretDocumentRequest,
    unknown
  >;
  validateSecretPhrase: (phrase: SafeWrapper<string>) => Promise<void>;
};

export const SigningContext = createContext<CxSigning | undefined>(undefined);

const GET_SD_QUERY_KEY = 'GetSecretDocument';

export function SigningProvider({ children }: PpWC): React.ReactElement {
  const { Enabled: enabled } = useFeature(Feature.Pin) || {};
  const queryClient = useQueryClient();
  /**
   * Invalidates the secret document query in order to refetch the updated value from the server
   */
  const refetchSd = useCallback(
    () => queryClient.invalidateQueries([GET_SD_QUERY_KEY]),
    [queryClient]
  );
  const { isFetched: isJwtFetched } = useSigningJwt({ enabled });

  const { data: sd, isSuccess: isSdFetched } = useGetSecretDocument(
    {
      documentType: 'NonCustodyKey',
    },
    {
      enabled: isJwtFetched,
      select: ({ data }) => (!isEmptyArray(data) ? data[0] : undefined),
    }
  );
  const { mutateAsync: postSecretDocument } = usePostSecretDocument({
    onSettled: refetchSd,
  });
  const { mutateAsync: postSecretDocumentSupersede } = usePostSecretDocumentSupersede({
    onSettled: refetchSd,
  });
  const { mutateAsync: revoke } = useRevokeSecretDocument({
    onSettled: refetchSd,
  });
  const { mutateAsync: unrevoke } = useUnrevokeSecretDocument();
  const { mutateAsync: decryptSecretDocument } = useDecryptSecretDocument({
    onSettled: refetchSd,
  });

  /**
   * SD is revoked if the RevokesAt date is in the past
   */
  const isSdRevoked = Boolean(sd?.RevokesAt) && dayjs(sd?.RevokesAt).isBefore(dayjs.utc());

  /**
   * If there is no SD or if it's revoked, a pin setup is required
   */
  const pinSetupRequired = isSdRevoked || (isSdFetched && isUndefinedOrNull(sd));

  const sdStatus = useMemo(
    () =>
      isSdFetched
        ? {
            pinSetupRequired,
            revoked: isSdRevoked,
            revokeStarted: !isUndefinedOrNull(sd?.RevokesAt),
          }
        : undefined,
    [sd, isSdFetched, isSdRevoked, pinSetupRequired]
  );

  /**
   * Derives the SCW by using the pin to decrypt the secret value from the enclave
   * Requests the secret value from the enclave by creating an app input using the pin
   * Decrypts the response to extract the pin encrypted secret value and decrypts it
   * @param pin - pin
   * @throws IncorrectPinError - the pin is incorrect and there are more attempts left
   * @throws LockedPinError - the pin is locked, meaning no more decrypt attempts are left
   */
  const deriveScw = useCallback(
    async (pin: SafePin): Promise<HDWallet> => {
      logger.info('Deriving scw...');

      if (!sd) {
        logger.error('Missing secret document, cannot attempt decrypt');
        throw new GenericPINError();
      }

      try {
        const { decryptionKey, encryptedAppInput, keyId } = await getSecretValueRequest(
          pin,
          sd.PinkSalt,
          isProd
        );

        const { data } = await decryptSecretDocument({
          appInput: encryptedAppInput,
          documentType: 'NonCustodyKey',
          keyId,
        });

        const pinEncryptedSv = await decryptEnclaveResponse(
          new SafeWrapper(data.SecretValue),
          decryptionKey
        );

        const scEntropy = await decryptSecretValue(pinEncryptedSv, pin, sd.PinkSalt);

        const scw = await HDWallet.fromEntropy(scEntropy);

        logger.success('Successfully derived scw');

        return scw;
      } catch (err) {
        return handleDecryptError(err, sd);
      }
    },
    [sd, decryptSecretDocument]
  );

  /**
   * Signs transaction payload with the NCK
   * @param pin - pin
   * @param req - signature request
   * @returns - signature to be sent to the signing service
   * @throws - error if unsuccessful
   */
  const createSignature = useCallback(
    async (pin: SafePin, payload: TpSignable): Promise<TpPayloadSignature> => {
      const scw = await deriveScw(pin);

      const wallet = await deriveNcwFromScw(scw);

      const timestamp = dayjs.utc().format();
      const nonce = window.crypto.randomUUID();

      let signature: string;

      switch (payload.inputType) {
        case 'payout':
          signature = await signPayload({
            payload: [
              nonce,
              timestamp,
              payload.CurrencyCode,
              payload.AccountType,
              payload.Amount,
              payload.Destination,
              payload.RequestedAmount.FiatCurrency,
              payload.RequestedAmount.Amount,
            ],
            timestamp,
            wallet,
          });

          return {
            nonce,
            signature,
          };

        case 'withdraw': {
          const payloadToSign = [
            nonce,
            timestamp,
            payload.CurrencyCode,
            payload.AccountType,
            payload.Amount,
            payload.Destination,
          ];

          if (payload.NetworkFee && !BigNumber(payload.NetworkFee).isZero()) {
            payloadToSign.push(`NetworkFee:${payload.NetworkFee}`);
          }

          signature = await signPayload({
            payload: payloadToSign,
            timestamp,
            wallet,
          });

          return {
            nonce,
            signature,
          };
        }

        case 'userLimit':
          signature = await signPayload({
            payload: [
              timestamp,
              payload.LimitType,
              payload.Period,
              payload.FiatCurrencyCode,
              payload.FiatAmount,
            ],
            timestamp,
            wallet,
          });

          return {
            nonce: '',
            signature,
          };
        default:
          throw new Error('Unsupported input type');
      }
    },
    [deriveScw]
  );

  /**
   * Creates a new wallet
   * @param pin - pin
   * @throws - error if unsuccessful
   */
  const createNewWallet = useCallback(
    async (pin: SafePin): Promise<SafeWrapper<string> | undefined> => {
      const pinData = await setupWallet({ isProd, pin });

      const {
        keyAlgorithm,
        keyId,
        mnemonic,
        pinkIterations,
        pinkKdf,
        pinkSalt,
        publicKey,
        secretDocument,
        signature,
        tag,
      } = pinData;

      await postSecretDocument({
        documentType: 'NonCustodyKey',
        keyAlgorithm,
        keyId,
        pinkIterations,
        pinkKdf,
        pinkSalt: pinkSalt.toBase64(),
        publicKey: publicKey.toBase64(),
        secretDocument: secretDocument.toBase64(),
        signature: signature.toBase64(),
        tag: tag.toBase64(),
      });

      return mnemonic;
    },
    [postSecretDocument]
  );

  /**
   * Recovers the wallet using the mnemonic phrase. The mnemonic, new and previous tags are used to prove ownership of the wallet.
   * The pin does not need to match the original pin used to create the wallet.
   * @param pin - pin
   * @param mnemonic - mnemonic phrase
   * @throws - error if unsuccessful
   */
  const recoverWallet = useCallback(
    async (pin: SafePin, scwMnemonic: SafeWrapper<string>): Promise<void> | never => {
      if (!sd) {
        logger.error('Missing secret document, cannot attempt recover');
        return;
      }

      const supersede = {
        mnemonic: scwMnemonic,
        prevTag: new SafeWrapper(sd.Tag).base64ToBuffer().value.secret,
      };

      const pinData = await setupWallet({ isProd, pin, supersede });

      const {
        keyAlgorithm,
        keyId,
        pinkIterations,
        pinkKdf,
        pinkSalt,
        publicKey,
        secretDocument,
        signature,
        supersedeSignature,
        tag,
      } = pinData;

      if (!supersedeSignature) {
        throw new Error('Missing supersede signature');
      }

      await postSecretDocumentSupersede({
        documentType: 'NonCustodyKey',
        keyAlgorithm,
        keyId,
        pinkIterations,
        pinkKdf,
        pinkSalt: pinkSalt.toBase64(),
        publicKey: publicKey.toBase64(),
        secretDocument: secretDocument.toBase64(),
        signature: signature.toBase64(),
        supersedeSeq: sd.Seq,
        supersedeSignature: supersedeSignature.toBase64(),
        tag: tag.toBase64(),
      });
    },
    [postSecretDocumentSupersede, sd]
  );

  const validateSecretPhrase = useCallback(
    async (phrase: SafeWrapper<string>): Promise<void> => {
      if (!sd) {
        throw new Error('Missing secret document, cannot attempt validation');
      }
      await validatePhrase(phrase, sd.PublicKey);
    },
    [sd]
  );

  const value = useMemo(
    () => ({
      createNewWallet,
      createSignature,
      deriveScw,
      isJwtFetched,
      recoverWallet,
      revoke,
      sdStatus,
      unrevoke,
      validateSecretPhrase,
    }),
    [
      isJwtFetched,
      unrevoke,
      createNewWallet,
      createSignature,
      revoke,
      recoverWallet,
      sdStatus,
      validateSecretPhrase,
      deriveScw,
    ]
  );

  return <SigningContext.Provider value={value}>{children}</SigningContext.Provider>;
}
