//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgSquareWarning } from '../icons/SquareWarning';

export function SquareWarningIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgSquareWarning} data-qa="SquareWarningIcon">
      <svg />
    </SvgIcon>
  );
}
