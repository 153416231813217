/**
 * This file can be used to override default props and styles on the various Material components
 * see: https://next.material-ui.com/customization/theme-components/
 */
import type { ThemeOptions } from '@mui/material';
import { getValueFromPath, Tokens } from './designTokens';
import type { TpTypography } from './typography';
import { typography } from './typography';

type TpVariantMappingKeys = keyof Omit<TpTypography, 'buttonL' | 'buttonM' | 'buttonS'>;

type TpVariantMapping = {
  [key in TpVariantMappingKeys]: string;
};

const variantMapping: TpVariantMapping = {
  headingL: 'h2',
  headingXL: 'h1',
  paragraphBodyL: 'p',
  paragraphBodyLBold: 'p',
  paragraphBodyM: 'p',
  paragraphBodyMBold: 'p',
  paragraphBodyS: 'p',
  paragraphBodySBold: 'p',
  subHeadingL: 'h2',
  subHeadingM: 'h2',
  subHeadingS: 'h2',
};

export const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '.MuiAlert-action': {
          paddingTop: 0,
        },
        '.MuiAlert-icon': {
          position: 'static',
        },
        alignItems: 'flex-start',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        ...typography.paragraphBodyMBold,
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        // to enable deliberate line breaks in validation messages etc.
        whiteSpace: 'pre-wrap',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      button: {
        display: 'inline',
        verticalAlign: 'initial',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },
    styleOverrides: {
      text: {
        borderRadius: '2px',
        display: 'inline-flex',
        height: '1em',
        padding: '0 2em',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        'input::placeholder': {
          color: getValueFromPath(Tokens.ColourUsage.Text.Light.value),
          opacity: 1,
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping,
    },
  },
};
