/* eslint-disable no-param-reassign */
import axios from 'axios';
import type { TpSegmentDestination } from './types';

/**
 * converted to use Axios from here: https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/fetch-destinations.ts
 */
async function fetchDestinationForWriteKey(
  cdnUrl: string,
  writeKey: string
): Promise<TpSegmentDestination[]> {
  const axiosClient = axios.create({
    baseURL: cdnUrl,
    timeout: 5000,
  });
  const res = await axiosClient.get<TpSegmentDestination[]>(`v1/projects/${writeKey}/integrations`);
  const destinations = res.data;

  // rename creationName to id to abstract the weird data model
  destinations.forEach((destination, index) => {
    // because of the legacy Fullstory integration the creationName for this integration is the `name`
    if (destination.name === 'Fullstory (Actions)') {
      destination.id = destination.name;
    } else {
      destination.id = destination.creationName;
    }
    // @ts-expect-error needed from segment's code
    delete destination.creationName;

    // remove dummy 'Repeater' destinations
    if (destination.id === 'Repeater') {
      destinations.splice(index, 1);
    }
  });

  return destinations;
}

export async function fetchDestinations(
  cdnUrl: string,
  writeKeys: string[]
): Promise<TpSegmentDestination[]> {
  const destinationsRequests = writeKeys.map((writeKey) =>
    fetchDestinationForWriteKey(cdnUrl, writeKey)
  );
  const allDestinations = await Promise.all(destinationsRequests);

  // flatten all the write key destinations
  const destinations = allDestinations.flat();

  // create a map to remove duplicates
  const destinationsMap = new Map(destinations.map((dest) => [dest.id, dest]));

  // return the array of de-duped destinations
  return [...destinationsMap.values()];
}
