import type { FullName } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { webConfigBrowser } from '../webConfigBrowser';

export function appendToTitle(title: string, append: string): string {
  return `${title} ${append}`;
}

export function getReferralUrl(referralCode: string | undefined): string | undefined {
  if (isUndefinedOrNull(referralCode)) {
    return undefined;
  }
  const referralUrl = new URL(webConfigBrowser.url);
  referralUrl.searchParams.set(webConfigBrowser.settings.referralCodeParam, referralCode);
  return referralUrl.href;
}

export function getUserFullDisplayName(fullName: FullName | null | undefined): string | undefined {
  if (isUndefinedOrNull(fullName)) {
    return undefined;
  }

  const { FirstName, LastName, MiddleName } = fullName;

  return [FirstName, MiddleName, LastName].filter(Boolean).join(' ');
}
