import dayjs from 'dayjs';
import type { UseQueryResult } from 'react-query';
import type { SigningJwtQuery } from './signing.generated';
import { useSigningJwtQuery } from './signing.generated';

const defaultRefetchInterval = dayjs.duration({ minutes: 1 }).asMilliseconds();

/**
 * Fetches the JWT token used for signing.
 * Token is refreshed automatically
 */
type PpUseSigningJwt = {
  enabled?: boolean;
};
export function useSigningJwt({ enabled = false }: PpUseSigningJwt = {}): UseQueryResult<
  SigningJwtQuery,
  unknown
> {
  return useSigningJwtQuery(undefined, {
    enabled,
    refetchInterval: defaultRefetchInterval,
  });
}
