/* eslint-disable react/no-multi-comp */
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { PpWC } from '../types';

export function SceneTitleLarge({ children }: PpWC): React.ReactElement {
  return (
    <Typography
      color="text.dark"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 2,
        },
        '+ .MuiTypography-subHeadingM': {
          mt: 1,
        },
      }}
      variant="headingL"
    >
      {children}
    </Typography>
  );
}

export function SceneTitleMedium({ children }: PpWC): React.ReactElement {
  return (
    <Typography
      color="text.dark"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 1,
        },
      }}
      variant="subHeadingL"
    >
      {children}
    </Typography>
  );
}
export function SceneSubTitle({ children }: PpWC): React.ReactElement {
  return (
    <Typography
      color="text.light"
      component="h3"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 1,
        },
      }}
      variant="subHeadingM"
    >
      {children}
    </Typography>
  );
}

type PpSceneParagraph = PpWC<Pick<TypographyProps, 'paragraph'>>;
export function SceneParagraph({ children, paragraph }: PpSceneParagraph): React.ReactElement {
  return (
    <Typography
      color="text.light"
      paragraph={paragraph}
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 2,
        },
      }}
      variant="paragraphBodyM"
    >
      {children}
    </Typography>
  );
}
