import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { SardineFlows } from '@noah-labs/shared-schema-gql';
import { Script } from 'gatsby';
import { useAuth } from '../../auth/hooks/useAuth';
import type {
  TpSardineConfigBase,
  TpSardineDeviceResponse,
  TpSardineFlowConfig,
  TpSardineWindowReturn,
} from '../types';

export type CxSardine = {
  deviceResponse: TpSardineDeviceResponse | null;
  sardineSessionKey: string;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
  updateSardineConfig: (config: TpSardineFlowConfig) => void;
};
export const SardineContext = createContext<CxSardine>({
  deviceResponse: null,
  sardineSessionKey: '',
  updateGuestSardineConfig: () => '',
  updateSardineConfig: () => {},
});

type PpSardineProvider = TpSardineConfigBase & {
  scriptUrl: string;
};
/**
 * Until the updateSardineConfig function is called, the sessionData will remain null.
 * Once the updateSardineConfig function is invoked with a configuration object, it updates the sessionData state with the provided configuration:
 * If the sardineConfig is already available (indicating that the script has been loaded), it calls the updateConfig function of the sardineConfig object.
 * Otherwise, it sets the sessionData state with the provided config object.
 * So, until the updateSardineConfig function is called and the sessionData state is updated with a valid configuration, the sessionData will indeed be null.
 *
 * The Sardine SDK seems to create a new `deviceID` each time the `sessionKey` is changed. In our previous
 * implementation, we weren't waiting for the user to be authenticated to set the `sessionKey` - we were using a random one.
 * Hence, each time the page was loaded, before user authentication state was known, we were initialising the SDK with a random `sessionKey`.
 * Then, when it did finally load, we updated it with the Ory session key, meaning each page load created at least 2 device IDs.
 *
 * Note: Nothing will appear in the Sardine dashboard when only using the SDK. It's not until we get a call to the `sardineDevices` mutation.
 *
 * Note: Currently, we don't create a session for guests - we may want to change this once we work on signup fraud.
 */
export function SardineProvider({
  children,
  clientId,
  environment,
  scriptUrl,
}: PpWC<PpSardineProvider>): React.ReactElement {
  const [sardineApi, setSardineApi] = useState<TpSardineWindowReturn | undefined>(undefined);
  const [sardineFlowConfig, setSardineFlowConfig] = useState<TpSardineFlowConfig | null>(null);
  const [deviceResponse, setDeviceResponse] = useState<TpSardineDeviceResponse | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    logger.debug('deviceResponse:', deviceResponse);
  }, [deviceResponse]);

  /**
   * 'Store' the script loaded status so we can know whether we can initialise (after the user is authenticated)
   */
  const handleScriptLoaded = useCallback(() => {
    setScriptLoaded(true);
  }, [setScriptLoaded]);

  useEffect(() => {
    /**
     * script isn't loaded or already called window._Sardine.createContext, don't do anything
     */
    // eslint-disable-next-line no-underscore-dangle
    if (!scriptLoaded || typeof window._Sardine?.createContext !== 'function' || sardineApi) {
      return;
    }
    /**
     * don't create the session if we don't yet have a key (i.e. updateSardineConfig has not been called)
     * for logged in users we'll use the Ory session ID
     * for user's who's auth status is loading and guests we will not yet create a session
     */
    if (!sardineFlowConfig?.sessionKey) {
      return;
    }

    const { flow, sessionKey, userIdHash } = sardineFlowConfig;
    logger.debug('creating sardine window context with flow', flow);
    // eslint-disable-next-line no-underscore-dangle
    const sardineContext = window._Sardine.createContext({
      clientId,
      enableBiometrics: true,
      enableGeoLocation: false,
      enablePortScanning: false,
      environment,
      fields: undefined,
      flow,
      onDeviceResponse: setDeviceResponse,
      parentElement: document.body,
      parentSessionKey: undefined,
      partnerId: undefined,
      sessionKey,
      userIdHash,
    });

    setSardineApi(sardineContext);
  }, [clientId, environment, sardineApi, scriptLoaded, sardineFlowConfig]);

  const updateSardineConfig = useCallback(
    (newConfig: TpSardineFlowConfig) => {
      // always store the config so it can be retrieved later, either once sardineApi is loaded, or if retriving the sessionKey
      setSardineFlowConfig(newConfig);

      if (!sardineApi) {
        logger.debug('sardineApi not yet loaded', newConfig);
        return;
      }
      // if the api is already loaded and set, call its update method
      logger.debug('sardineApi.updateConfig', newConfig);
      sardineApi.updateConfig(newConfig);
    },
    [sardineApi]
  );

  const updateGuestSardineConfig = useCallback(
    (flow) => {
      const sessionKey = crypto.randomUUID();
      updateSardineConfig({
        flow,
        sessionKey,
        userIdHash: undefined,
      });
      return sessionKey;
    },
    [updateSardineConfig]
  );

  /**
   * Use a similar same signature as the official react package for consistency: https://docs.sardine.ai/docs/risk-sdk/reactjs,
   * logoutUserOnSardine is not included because we use our own signout subsciber
   * sardineSessionKey replaces getSardineSessionKey and simply returns the sessionKey, rather than a function that expects a callback
   */
  const value = useMemo<CxSardine>(
    () => ({
      deviceResponse,
      sardineSessionKey: sardineFlowConfig?.sessionKey || '',
      updateGuestSardineConfig,
      updateSardineConfig,
    }),
    [deviceResponse, sardineFlowConfig?.sessionKey, updateSardineConfig, updateGuestSardineConfig]
  );

  /**
   * subscribe to the signout callback and end the sardine session
   */
  const { addSignOutSubscriber } = useAuth();
  const endSession = useCallback(() => {
    if (!sardineApi) {
      return;
    }

    // this is the same as what happens in logoutUserOnSardine in the official SDK
    sardineApi.updateConfig({ userIdHash: undefined });
  }, [sardineApi]);
  useEffect(() => addSignOutSubscriber('sardine', endSession), [addSignOutSubscriber, endSession]);

  return (
    <SardineContext.Provider value={value}>
      <Script src={scriptUrl} onLoad={handleScriptLoaded} />
      {children}
    </SardineContext.Provider>
  );
}
