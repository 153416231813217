import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import type { TpUseQueryResultReplacedData } from '../types';
import { useMoonpayCurrenciesQuery } from './currencies';
import { moonpayApi } from './moonpay';
import type { TpMoonpaySellTransaction, TpMoonpayTransaction } from './types';

export type TpMoonpayTransactionWithTotal = TpMoonpayTransaction & {
  totalBaseCurrencyAmount: number;
};
type TpExtendedMoonpayTransaction<T> = T & {
  fiatCurrencyCode: string | undefined;
};

async function moonpayTransactionRequest(
  transactionId: string
): Promise<TpMoonpayTransactionWithTotal> {
  const { data } = await moonpayApi
    .client()
    .get<TpMoonpayTransaction>(`v1/transactions/${transactionId}`);

  /**
   * When areFeesIncluded is true it means that when customer entered $30 to buy,
   * Moonpay deducted the fees from that. If it was false it means they added fees on top.
   * areFeesIncluded=true is now their default behavior.
   * baseCurrencyAmount is what was actually used to purchase the crypto.
   * When you add baseCurrencyAmount + feeAmount + extraFeeAmount + networkFeeAmount you'll get the amount that the customer originally entered
   */
  const totalBaseCurrencyAmount =
    [data.baseCurrencyAmount, data.extraFeeAmount, data.feeAmount, data.networkFeeAmount].reduce(
      (sum, amount) => (sum || 0) + (amount || 0),
      0
    ) || 0;

  return { ...data, totalBaseCurrencyAmount };
}

async function moonpaySellTransactionRequest(
  transactionId: string
): Promise<TpMoonpaySellTransaction> {
  const { data } = await moonpayApi
    .client()
    .get<TpMoonpaySellTransaction>(`v3/sell_transactions/${transactionId}`);
  return data;
}

export function useMoonpayTransactionQuery(
  transactionId: string,
  options?: UseQueryOptions<TpMoonpayTransactionWithTotal>
): TpUseQueryResultReplacedData<
  TpMoonpayTransactionWithTotal,
  TpExtendedMoonpayTransaction<TpMoonpayTransactionWithTotal>
> {
  const { data, ...rest } = useQuery(
    ['moonpay/transactions', transactionId],
    async () => moonpayTransactionRequest(transactionId),
    {
      ...options,
      enabled: Boolean(transactionId),
      refetchInterval: duration.seconds(10),
    }
  );

  const { data: currency } = useMoonpayCurrenciesQuery(data?.baseCurrencyId);

  const extendedData = data
    ? {
        ...data,
        fiatCurrencyCode: currency?.code.toUpperCase(),
      }
    : undefined;

  return {
    data: extendedData,
    ...rest,
  };
}

export function useMoonpaySellTransactionQuery(
  transactionId: string,
  options?: UseQueryOptions<TpMoonpaySellTransaction>
): TpUseQueryResultReplacedData<
  TpMoonpaySellTransaction,
  TpExtendedMoonpayTransaction<TpMoonpaySellTransaction>
> {
  const { data, ...rest } = useQuery(
    ['moonpay/sell_transactions', transactionId],
    async () => moonpaySellTransactionRequest(transactionId),
    {
      ...options,
      enabled: Boolean(transactionId),
      refetchInterval: duration.seconds(10),
    }
  );

  const { data: currency } = useMoonpayCurrenciesQuery(data?.baseCurrencyId);

  const extendedData = data
    ? {
        ...data,
        fiatCurrencyCode: currency?.code.toUpperCase(),
      }
    : undefined;

  return {
    data: extendedData,
    ...rest,
  };
}
