import React, { useEffect } from 'react';
import { mapCustomPreferences, useDestinations } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { TpAuthStatus } from '../../auth/types';
import { useUserInit } from '../../user/data/useUserInit';
import { AnalyticsContext } from './AnalyticsContext';
import { getConsentMiddleware } from './getConsentMiddleware';
import { useAnalyticsContext } from './useAnalyticsContext';

type PpAnalyticsProvider = {
  apiHost: string;
  cdnUrl: string;
  children: React.ReactNode;
  writeKey: string;
};
/**
 * Loads the Segment tracking script
 * Tracking calls are buffered / queued until ajs.load is invoked
 * Only calls the load method once we know the users preferences, so that we can respect their privacy choices
 */
export function AnalyticsProvider({
  apiHost,
  cdnUrl,
  children,
  writeKey,
}: PpAnalyticsProvider): React.ReactElement {
  const ajs = useAnalyticsContext();
  const userInit = useUserInit();
  const { data: { destinations } = {} } = useDestinations([writeKey], cdnUrl);
  const { addSignOutSubscriber, authStatus } = useAuth();

  useEffect(() => {
    async function loadAnalytics(): Promise<void> {
      // wait until we get destinations data
      if (!destinations) {
        logger.debug('destinations not available');
        return;
      }

      if (authStatus === TpAuthStatus.unknown) {
        logger.debug('auth status unknown');
        return;
      }

      // if the user is authenticated, wait until we get the user's consent preferences
      if (authStatus === TpAuthStatus.authenticated && !userInit.isFetched) {
        logger.debug('user is authed but cookie prefs not yet fetched');
        return;
      }

      logger.debug('loading analytics');

      // setup some defaults in case the user has not yet set preferences
      const {
        advertising = false,
        functional = true,
        marketingAndAnalytics = false,
      } = userInit.userSettingsConsent?.Cookies || {};

      const cookiePrefs = {
        advertising,
        functional,
        marketingAndAnalytics,
      };

      // get the enabled destinations depending on the user's consent prefs
      const integrations = mapCustomPreferences({
        analyticsEnabled: webConfigBrowser.analytics.enabled,
        apiHost,
        customPreferences: cookiePrefs,
        destinations,
      });

      // load the Segment analytics script, this should only be done once
      ajs.load({ cdnURL: cdnUrl, writeKey }, { initialPageview: false, integrations });

      // add the consent prefs to all the tracking calls
      const consentMiddleware = getConsentMiddleware(cookiePrefs);
      await ajs.addSourceMiddleware(consentMiddleware);

      logger.success('loading analytics');

      // reset analytics when we user signs out
      addSignOutSubscriber('ajs', ajs.reset);
    }
    void loadAnalytics();
  }, [
    addSignOutSubscriber,
    ajs,
    apiHost,
    authStatus,
    cdnUrl,
    destinations,
    userInit.isFetched,
    userInit.userSettingsConsent?.Cookies,
    writeKey,
  ]);

  return <AnalyticsContext.Provider value={ajs}>{children}</AnalyticsContext.Provider>;
}
