import { categories } from './categories';
import type { TpNormalisedDestinations, TpSegmentDestination } from './types';

/**
 * groupDestinations creates a structured object out of the destinations array so that the destination can be listed in the cookie option
 */
export function groupDestinations(destinations: TpSegmentDestination[]): TpNormalisedDestinations {
  const advertising: TpSegmentDestination[] = [];
  const functional: TpSegmentDestination[] = [
    {
      // Segment doesn't return itself as an integration destination but we should include it in the functional destinations
      category: 'functional',
      creationName: '',
      description: '',
      id: 'Segment.io',
      name: 'Segment',
      website: '',
    },
  ];
  const marketingAnalytics: TpSegmentDestination[] = [];

  destinations.forEach((destination) => {
    if (categories.advertising.find((c) => c === destination.category)) {
      advertising.push(destination);
    } else if (categories.marketingAndAnalytics.find((c) => c === destination.category)) {
      marketingAnalytics.push(destination);
    } else if (categories.functional.find((c) => c === destination.category)) {
      functional.push(destination);
    }
  });

  return {
    advertising,
    functional,
    marketingAnalytics,
  };
}
