/* eslint-disable no-underscore-dangle */
import type { BIP32Interface } from 'bip32';
import BIP32Factory from 'bip32';
import * as bip39 from 'bip39';
import { mnemonicEntropy } from './constants';
import { SafeWrapper } from './types';

export class HDWallet {
  private readonly _mnemonic: SafeWrapper<string>;

  private readonly _hdRoot: BIP32Interface;

  private readonly _seed: SafeWrapper<ArrayBuffer>;

  constructor(
    mnemonic: SafeWrapper<string>,
    hdRoot: BIP32Interface,
    seed: SafeWrapper<ArrayBuffer>
  ) {
    this._mnemonic = mnemonic;
    this._hdRoot = hdRoot;
    this._seed = seed;
  }

  static async generate(): Promise<HDWallet> {
    const mnemonic = bip39.generateMnemonic(mnemonicEntropy);
    return HDWallet.createFromMnemonic(new SafeWrapper(mnemonic));
  }

  static async fromMnemonic(mnemonic: SafeWrapper<string>): Promise<HDWallet> {
    return HDWallet.createFromMnemonic(mnemonic);
  }

  static async fromEntropy(entropy: SafeWrapper<ArrayBuffer>): Promise<HDWallet> {
    const mnemonic = bip39.entropyToMnemonic(Buffer.from(entropy.value.secret));
    return HDWallet.createFromMnemonic(new SafeWrapper(mnemonic));
  }

  private static async createFromMnemonic(mnemonic: SafeWrapper<string>): Promise<HDWallet> {
    const seed = await bip39.mnemonicToSeed(mnemonic.value.secret);
    const ecc = await import('tiny-secp256k1');
    const hdRoot = BIP32Factory(ecc).fromSeed(Buffer.from(seed));
    return new HDWallet(mnemonic, hdRoot, new SafeWrapper(seed));
  }

  get entropy(): ArrayBuffer {
    return Buffer.from(bip39.mnemonicToEntropy(this.mnemonic.value.secret), 'hex');
  }

  get mnemonic(): SafeWrapper<string> {
    return this._mnemonic;
  }

  get seed(): SafeWrapper<ArrayBuffer> {
    return this._seed;
  }

  get hdRoot(): BIP32Interface {
    return this._hdRoot;
  }

  signer(path: string): BIP32Interface {
    return this._hdRoot.derivePath(path);
  }
}
