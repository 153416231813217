import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { cssMixins } from '../theme/cssMixins';
import type { PpWC } from '../types';

export function AppNavbar({ children }: PpWC): React.ReactElement {
  const theme = useTheme();
  const color = theme.palette.grayscale.white;

  const styles = {
    container: css`
      background-color: ${color};
      ${cssMixins.footer(theme)};
      padding-top: 0;

      &::before {
        ${cssMixins.fade(color)};
        height: ${theme.spacing(6)};
        top: -${theme.spacing(5.9)};
      }
    `,
  };

  return <footer css={styles.container}>{children}</footer>;
}
