import { authPaths, idAndPath } from '@noah-labs/shared-constants';
import { AuthGroups } from '@noah-labs/shared-schema-gql';

const guestGroups = new Set([AuthGroups.guest]);
const personalGroups = new Set([AuthGroups.personal_basic]);

export const routes = {
  base: {
    ...idAndPath(authPaths.base),
    groups: guestGroups,
    title: '',
  },
  error: {
    ...idAndPath(authPaths.error),
    groups: guestGroups,
    title: 'Something went wrong',
  },
  recovery: {
    ...idAndPath(authPaths.recovery),
    groups: guestGroups,
    title: 'Forgotten password',
  },
  signedOut: {
    ...idAndPath(authPaths.signedOut),
    groups: guestGroups,
    title: 'Signed Out',
  },
  signIn: {
    ...idAndPath(authPaths.signIn),
    groups: guestGroups,
    title: 'Sign In',
  },
  signOut: {
    ...idAndPath(authPaths.signOut),
    title: 'Sign Out',
  },
  signUp: {
    ...idAndPath(authPaths.signUp),
    groups: guestGroups,
    title: 'Sign Up',
  },
  verify: {
    ...idAndPath(authPaths.verify),
    groups: personalGroups,
    title: 'Verify Email',
  },
};
