import { categories } from './categories';
import type {
  TpSegmentCategories,
  TpSegmentDestination,
  TpSegmentIntegrations,
  TpSegmentSettings,
} from './types';

/**
 * mapCustomPreferences loops through our destinations and, according to the user's customPreferences,
 * tells Segment to include the relevant destinations or not on the page
 */

type PpMapCustomPreferences = {
  analyticsEnabled: boolean;
  apiHost?: string;
  customPreferences: TpSegmentCategories;
  destinations: TpSegmentDestination[];
};
export function mapCustomPreferences({
  analyticsEnabled,
  apiHost,
  customPreferences,
  destinations,
}: PpMapCustomPreferences): TpSegmentIntegrations {
  let segmentSettings: TpSegmentSettings = analyticsEnabled;
  if (analyticsEnabled && apiHost) {
    segmentSettings = {
      apiHost: [apiHost, 'v1'].join('/'),
      protocol: 'https',
    };
  }
  const destinationPreferences: TpSegmentIntegrations = {
    All: false,
    // always enable the Segment & AWS S3 destinations if analytics is enabled
    'AWS S3': analyticsEnabled,
    'Segment.io': segmentSettings,
  };

  destinations.forEach((destination) => {
    // Return early if the destination is already set to true
    if (destinationPreferences[destination.id]) {
      return;
    }
    // Mark functional destinations
    if (categories.functional.find((c) => c === destination.category)) {
      destinationPreferences[destination.id] = Boolean(customPreferences.functional);
      return;
    }
    if (!analyticsEnabled) {
      // if we're not analyticsEnabled, exit
      return;
    }
    // Mark advertising destinations
    if (categories.advertising.find((c) => c === destination.category)) {
      destinationPreferences[destination.id] = Boolean(customPreferences.advertising);
      return;
    }
    // Fallback to marketing / analytics preference selection
    destinationPreferences[destination.id] = Boolean(customPreferences.marketingAndAnalytics);
  });

  return destinationPreferences;
}
