import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { webConfigBrowser } from '../../../webConfigBrowser';

function isCurrencyCode(currencyCode: string): currencyCode is CurrencyCode {
  return Object.values(CurrencyCode).includes(currencyCode as CurrencyCode);
}

export function getCurrenciesFromConfig(): CurrencyCode[] {
  const currencyKeys = Object.keys(webConfigBrowser.currencies.configurations);
  const currencySet = new Set<CurrencyCode>();

  currencyKeys.forEach((key) => {
    const currencyCode = key.split('/')[1];
    if (isCurrencyCode(currencyCode)) {
      currencySet.add(currencyCode);
    }
  });

  return Array.from(currencySet);
}
