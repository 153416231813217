import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { moonpayApi } from './moonpay';
import type { TpMoonpaySellQuote } from './types';

type PpUseMoonpaySellQuoteQuery = {
  // baseCurrencyCode: The code of the base currency used for the transaction.
  // quoteCurrencyCode: The code of the currency of interest.
  baseCurrencyAmount: TpAmount;
  baseCurrencyCode?: string;
  quoteCurrencyCode?: string;
};

export function useMoonpaySellQuoteQuery({
  baseCurrencyAmount = '',
  baseCurrencyCode = '',
  quoteCurrencyCode = '',
}: PpUseMoonpaySellQuoteQuery): UseQueryResult<TpMoonpaySellQuote> {
  return useQuery(
    ['moonpay/currencies/sell_quote', baseCurrencyAmount, baseCurrencyCode, quoteCurrencyCode],
    async () => {
      const { data } = await moonpayApi
        .client()
        .get<TpMoonpaySellQuote>(`v3/currencies/${baseCurrencyCode}/sell_quote`, {
          params: {
            baseCurrencyAmount,
            quoteCurrencyCode,
          },
        });
      return data;
    },
    {
      enabled: Boolean(baseCurrencyAmount && baseCurrencyCode && quoteCurrencyCode),
    }
  );
}
