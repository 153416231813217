import { OnAccountUpdateDocument } from './subscriptions.generated';

export enum TpSubId {
  onAccountUpdate = 'onAccountUpdate',
}

export type TpSubscribeQuery = {
  id: TpSubId;
  query: string;
  variables?: Record<string, unknown>;
};

export const subscriptions = [
  {
    id: TpSubId.onAccountUpdate,
    query: OnAccountUpdateDocument,
  },
];
