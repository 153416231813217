import { css } from '@emotion/react';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cssMixins } from '../theme/cssMixins';
import type { PpWC } from '../types';

export type PpAppFooter = PpWC<{
  backgroundColor?: string;
  disableFade?: boolean;
  hasContent?: boolean;
  solidFade?: boolean;
  wide?: boolean;
}>;

export function AppFooter({
  backgroundColor,
  children,
  disableFade,
  hasContent = false,
  solidFade,
  wide,
}: PpAppFooter): React.ReactElement {
  const theme = useTheme();
  const fadeColor = backgroundColor || theme.palette.grayscale.white;
  const fadeHeight = hasContent ? theme.spacing(2) : theme.spacing(2.5);

  const styles = {
    fade: css`
      &::before {
        ${cssMixins.fade(fadeColor)};
        box-sizing: content-box;
        height: 100%;
        top: -${fadeHeight};
        padding-top: ${fadeHeight};
      }
    `,
    footer: css`
      ${cssMixins.footer(theme)};
    `,
    hasContent: css`
      background: ${fadeColor};
      &::before {
        ${cssMixins.fade(fadeColor)};
        box-sizing: content-box;
        height: ${fadeHeight};
        top: -${fadeHeight};
      }
    `,
    hasNoContent: css`
      background: transparent;
    `,
    solidFade: css`
      ${cssMixins.footerSolidFade(fadeColor)};
    `,
  };

  const footerStyles = [styles.footer];
  if (hasContent) {
    footerStyles.push(styles.hasContent);
  } else {
    footerStyles.push(styles.hasNoContent);
  }
  if (!disableFade) {
    footerStyles.push(styles.fade);
  }
  if (solidFade) {
    footerStyles.push(styles.solidFade);
  }

  return (
    <footer css={footerStyles}>
      <Container maxWidth={wide ? 'sm' : 'xs'}>{children}</Container>
    </footer>
  );
}
