import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { disableRefetchRetry } from '../noah';
import { fetchDestinations } from './destinations';
import { groupDestinations } from './groupDestinations';
import type { TpNormalisedDestinations, TpSegmentDestination } from './types';

const defaultCdnUrl = 'https://cdn.segment.com';

/**
 * fetches destinatons from Segment and prevents refetching / reloading
 */
type TpUseDestinations = {
  destinations: TpSegmentDestination[];
  groupedDestinations: TpNormalisedDestinations;
};
export function useDestinations(
  writeKeys: string[],
  cdnUrl = defaultCdnUrl
): UseQueryResult<TpUseDestinations> {
  return useQuery(
    ['SegmentDestinations'],
    async () => {
      const destinations = await fetchDestinations(cdnUrl, writeKeys);
      const grouped = groupDestinations(destinations);
      return {
        destinations,
        groupedDestinations: grouped,
      };
    },
    {
      ...disableRefetchRetry,
      staleTime: duration.days(1),
    }
  );
}
