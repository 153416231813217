import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { moonpayApi } from './moonpay';
import type { TpMoonpayCurrency } from './types';

type TpUseMoonpayCurrenciesQueryResult = TpMoonpayCurrency | undefined;

export function useMoonpayCurrenciesQuery(
  mpCurrId: string | undefined
): UseQueryResult<TpUseMoonpayCurrenciesQueryResult> {
  return useQuery<TpMoonpayCurrency[], unknown, TpUseMoonpayCurrenciesQueryResult>(
    ['moonpay/currencies'],
    async () => {
      const { data } = await moonpayApi.client().get<TpMoonpayCurrency[]>('v3/currencies');
      return data;
    },
    {
      enabled: Boolean(mpCurrId),
      select: (data) => data.find((curr) => curr.id === mpCurrId),
      staleTime: 600000, // 10 mins, this does not need to be fetched often
    }
  );
}
