export const authPaths = {
  base: '/auth',
  error: '/auth/error',
  recovery: '/auth/recovery',
  signedOut: '/auth/signedout',
  signIn: '/auth/signin',
  signOut: '/auth/signout',
  signUp: '/auth/signup',
  verify: '/auth/verify',
} as const;

export const assetsHost = 'assets.noah.com';
