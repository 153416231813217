import type { Variables } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';

type TpApiConfig = {
  apiKey: string;
  path: string;
};

class Api {
  public config: undefined | TpApiConfig;

  public gqlClient = new GraphQLClient('', {
    credentials: 'include',
    mode: 'cors',
  });

  public configure(config: TpApiConfig): void {
    this.config = config;
    this.gqlClient.setEndpoint(this.config.path);
  }
}

/**
 * Only export a singleton of our Api class (similar to the Auth class from Amplify)
 * Only to be used at the 'base' of the App because it must only be called once
 * Call api.configure to setup the api with the appsync config
 */
export const api = new Api();

/**
 * appSyncRequest is used in codegen as our custom fetcher for react-query
 */
export function useAppSyncRequest<TData, TVariables extends Variables | undefined>(
  doc: string
): (vars?: TVariables) => Promise<TData> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  return async (vars?: TVariables): Promise<TData> => api.gqlClient.request(doc, vars, headers);
}

export function usePublicAppSyncRequest<TData, TVariables extends Variables | undefined>(
  doc: string
): (vars?: TVariables) => Promise<TData> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-api-key': api.config?.apiKey || '',
  };

  return async (vars?: TVariables): Promise<TData> => api.gqlClient.request(doc, vars, headers);
}
