import { css } from '@emotion/react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronLeftIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronLeftIcon';
import { useHistory } from 'react-router-dom';
import { HeaderIconButton } from '../buttons/HeaderIconButton';
import { cssMixins } from '../theme/cssMixins';
import type { PpWOC } from '../types';
import { BalancedStack } from './BalancedStack';

export type PpAppHeader = PpWOC & {
  backButton?: boolean;
  backTo?: string;
  backgroundColor?: string;
  disableBalancing?: boolean;
  disableFade?: boolean;
  endIconsSlot?: React.ReactNode;
  fontColor?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
};

export function AppHeader({
  backButton = false,
  backgroundColor,
  backTo,
  children,
  disableBalancing,
  disableFade = false,
  endIconsSlot,
  fontColor,
  fullWidth,
  sx,
}: PpAppHeader): React.ReactElement {
  const theme = useTheme();
  const history = useHistory();

  let backButtonProps = null;
  if (backButton) {
    backButtonProps = {
      onClick: (): void => {
        history.goBack();
      },
    };
  } else if (backTo) {
    backButtonProps = { href: backTo };
  }

  const fadeColor = backgroundColor || theme.palette.grayscale.white;

  const styles = {
    header: css`
      position: sticky;
      /* Needed to make position: sticky work */
      top: 0;
      width: 100%;
      padding: ${theme.spacing(1, 0)};
      z-index: ${theme.zIndex.appBar};
      color: ${fontColor || theme.palette.text.primary};
      background: ${fadeColor};
      ${theme.breakpoints.up('lg')} {
        padding-top: ${theme.spacing(1.5)};
      }
    `,
    /**
     * when header has a fade, we use a bottom margin instead of bottom padding
     * so that the fade can start 'earlier'
     * fade height is 8px more than header margin
     */
    headerFade: css`
      margin-bottom: ${theme.spacing(1)};
      padding-bottom: 0;

      &::before {
        ${fadeColor === 'transparent' ? undefined : cssMixins.fade(fadeColor)};
        height: ${theme.spacing(2)};
        top: 100%;
        transform: rotate(180deg);
      }
    `,
  };

  const headerStyles = [styles.header];
  if (!disableFade) {
    headerStyles.push(styles.headerFade);
  }

  return (
    <Box component="header" css={headerStyles} sx={sx}>
      <Container maxWidth={fullWidth ? false : 'sm'}>
        <BalancedStack
          disableBalancing={disableBalancing}
          EndIcons={endIconsSlot}
          height={theme.shape.appHeaderHeight}
          StartIcons={
            backButtonProps && (
              <HeaderIconButton {...backButtonProps} aria-label="back" edge="start">
                <ChevronLeftIcon />
              </HeaderIconButton>
            )
          }
        >
          {children}
        </BalancedStack>
      </Container>
    </Box>
  );
}
