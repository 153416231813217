export function createInternetID(user: string, domain: string): string {
  return `${user}@${domain}`;
}

export function getUserPayLink(domain: string, userName: string): string {
  return `https://${domain}/.well-known/lnurlp/${userName.toLowerCase()}`;
}

/**
 * See the tests in: packages/noah-infra/src/application/api/resolvers/addUserProfileCreateResolver.test.ts
 * (?=.{3,25}$) - restricts the whole string to be between 3 and 25 chars
 * ^(?=.{3,25}$)[A-Za-z0-9]+ - means the string should start with at least one of a-z, A-Z or 0-9
 * [\\.\\-_]? - means . - _ are optional
 * ([\\.\\-_]?[A-Za-z0-9])+$ - is a group and means the string must end with one or more or of a-z, A-Z or 0-9 and can have . - _ in the middle
 */
export const userNameRegexString = '^(?=.{3,25}$)[A-Za-z0-9]+([\\.\\-_]?[A-Za-z0-9])+$';

export function usernameFromEmail(email: string): string {
  const usernameReplace = /[^-_a-zA-Z0-9]+/g; // regex for removing unsupported characters from email
  return email.split('@')[0].replace(usernameReplace, '').substring(0, 24);
}

export function capitalizeFirstLetter(string: string): Capitalize<string> {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<string>;
}

export function toTitleCase(string: string): Capitalize<string> {
  return string
    .toLowerCase()
    .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase()) as Capitalize<string>;
}

export function compareStrings(s1: string | unknown, s2: string | unknown): boolean {
  if (typeof s1 !== 'string' || typeof s2 !== 'string') {
    return false;
  }
  return s1.toLowerCase() === s2.toLowerCase();
}
