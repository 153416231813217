//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgSquareInfo } from '../icons/SquareInfo';

export function SquareInfoIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgSquareInfo} data-qa="SquareInfoIcon">
      <svg />
    </SvgIcon>
  );
}
