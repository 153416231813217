import { useEffect } from 'react';
import { disableRefetchRetry } from '@noah-labs/core-services';
import { useOry } from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { AuthGroups } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { withTimeout } from '@noah-labs/shared-tools/src/browser/utils';
import type { Session } from '@ory/client';
import axios from 'axios';
import { useQuery } from 'react-query';
import { routes } from '../routes';
import type { TpUseQueryResultReplacedData } from '../types';
import { TpAuthStatus } from '../types';
import { useAuthError } from './useAuthError';

/**
 * Add some typeguard helper functions
 */
type TpAuthUserTraits = {
  email: string;
};
function isTpAuthUserTraits(traits: unknown): traits is TpAuthUserTraits {
  if (!traits || typeof traits !== 'object') {
    return false;
  }
  const { email } = traits as TpAuthUserTraits;
  if (!email) {
    return false;
  }
  return true;
}

type TpAuthUserPublicMetadata = {
  referralCode?: string;
  userId: string;
};
function isTpAuthUserPublicMetadata(
  publicMetadata: unknown
): publicMetadata is TpAuthUserPublicMetadata {
  if (!publicMetadata || typeof publicMetadata !== 'object') {
    return false;
  }
  const { userId } = publicMetadata as TpAuthUserPublicMetadata;
  if (!userId) {
    return false;
  }
  return true;
}

function hasVerifiedEmail(email: string, session: Session | undefined): boolean {
  return Boolean(
    session?.identity?.verifiable_addresses?.find(
      (address) => address.verified && compareStrings(address.value, email)
    )
  );
}

/**
 * Setup signoutSubscribers and onSignOut callback
 */
type TpFunc = (() => void) | (() => Promise<void>);

const signoutSubscribers = new Map<string, TpFunc>();
function addSignOutSubscriber(name: string, sub: TpFunc): void {
  signoutSubscribers.set(name, sub);
}

async function onSignOut(): Promise<void> {
  try {
    logger.debug('calling subscribers');
    const promises: Array<ReturnType<TpFunc>> = [];
    signoutSubscribers.forEach((cb) => {
      // wrap the callbacks in a raced promise of 1s to ensure they resolve and not block signout
      promises.push(withTimeout(cb(), duration.seconds(1)));
    });

    await Promise.allSettled(promises);
    signoutSubscribers.clear();
  } catch (error) {
    // we wouldn't really want this to block the logout
    logger.error(error);
  }
}

/**
 * Main useAuth function
 */
type TpOrySession = Session | undefined;

type TpUseAuthData = {
  authGroups: AuthGroups[];
  createdAt: string | undefined;
  email: string | undefined;
  referralCode: string | undefined;
  sessionId: string | undefined;
  userId: string | undefined;
  verified: boolean;
};

type TpUseAuth = TpUseQueryResultReplacedData<TpOrySession, TpUseAuthData> & {
  AuthErrorScene: React.ReactElement | null;
  addSignOutSubscriber: (name: string, sub: TpFunc) => void;
  authStatus: TpAuthStatus;
  isAuthenticated: boolean;
  onSignOut: () => Promise<void>;
};

export const orySessionKey = ['ory/session'];

let hasSession: boolean;
export function useAuth(): TpUseAuth {
  const { ory } = useOry();

  const { data: session, ...oryResponse } = useQuery(
    orySessionKey,
    async () => {
      try {
        const orySession = await ory.toSession();
        logger.debug('orySession:', orySession.data);

        hasSession = true;

        return orySession.data;
      } catch (error) {
        /**
         * User is not authenticated - call onSignOut
         */
        await onSignOut();

        /**
         * If the error is a 401 it just means the user is not logged in, we can continue
         */
        if (!axios.isAxiosError(error) || error.response?.status !== 401) {
          throw error;
        }

        // Redirect to SignedOut scene if user has an expired session
        if (hasSession) {
          hasSession = false;
          window.location.assign(routes.signedOut.path);
        }

        return undefined;
      }
    },
    {
      ...disableRefetchRetry,
      refetchInterval: duration.minutes(5),
    }
  );

  /**
   * Check if email address is verified
   */
  const { traits } = session?.identity || {};
  const email = isTpAuthUserTraits(traits) ? traits.email : '';
  const verified = hasVerifiedEmail(email, session);
  const createdAt = session?.identity?.created_at;

  /**
   * Set the Auth Status
   */
  let authStatus = TpAuthStatus.unknown;
  const isAuthenticated = Boolean(session?.identity);
  if (oryResponse.isFetched) {
    authStatus = isAuthenticated ? TpAuthStatus.authenticated : TpAuthStatus.guest;
  }

  /**
   * Get userID from metadata_public
   */
  const { metadata_public: mp } = session?.identity || {};
  const { referralCode, userId } = isTpAuthUserPublicMetadata(mp)
    ? mp
    : { referralCode: undefined, userId: undefined };

  /**
   * Check for Auth Errors
   */
  const { AuthErrorScene } = useAuthError({ error: oryResponse.error });

  const baseAuth = {
    ...oryResponse,
    addSignOutSubscriber,
    AuthErrorScene,
    authStatus,
    onSignOut,
  };
  let auth: TpUseAuth;
  switch (authStatus) {
    case TpAuthStatus.unknown:
      auth = {
        ...baseAuth,
        data: undefined,
        isAuthenticated: false,
      };
      break;

    case TpAuthStatus.guest:
      auth = {
        ...baseAuth,
        data: {
          authGroups: [AuthGroups.guest],
          createdAt: undefined,
          email,
          referralCode: undefined,
          sessionId: '',
          userId: undefined,
          verified: false,
        },
        isAuthenticated: false,
      };
      break;

    case TpAuthStatus.authenticated:
    default:
      auth = {
        ...baseAuth,
        data: {
          authGroups: [AuthGroups.personal_basic],
          createdAt,
          email,
          referralCode,
          sessionId: session?.id,
          userId,
          verified,
        },
        isAuthenticated: true,
      };
      break;
  }

  useEffect(() => {
    logger.debug('auth:', auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.dataUpdatedAt]);

  return auth;
}
