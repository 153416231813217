import React, { createContext, useMemo, useState } from 'react';
import { Configuration, FrontendApi } from '@ory/client';
import { getOryUrls } from '../utils/getOryUrls';

export type CxOry = {
  ory: FrontendApi;
  returnTo: string;
  setReturnPath: (path: string) => void;
};
export const OryContext = createContext<CxOry | undefined>(undefined);

export type PpOryProvider = {
  appUrl: string;
  children: React.ReactNode;
  oryCustomUrl: string;
  searchParams?: Record<string, string>;
};

export function OryProvider({
  appUrl,
  children,
  oryCustomUrl,
  searchParams,
}: PpOryProvider): React.ReactElement {
  const [returnPath, setReturnPath] = useState('');

  const value = useMemo(() => {
    const ory = new FrontendApi(
      new Configuration({
        baseOptions: {
          withCredentials: true,
        },
        basePath: oryCustomUrl,
      })
    );

    return {
      ...getOryUrls({ appUrl, oryCustomUrl, returnPath, searchParams }),
      ory,
      setReturnPath,
    };
  }, [appUrl, oryCustomUrl, returnPath, searchParams]);

  return <OryContext.Provider value={value}>{children}</OryContext.Provider>;
}
