import { css } from '@emotion/css';
import { assetPaths } from './assetPaths';

type PpCreateFontCss = {
  family: string;
  path: string;
  style: 'normal' | 'italic';
  variant: string;
  weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
};
export function createFontFace({ family, path, style, variant, weight }: PpCreateFontCss): string {
  const fullPath = `${assetPaths.fonts}/${path}/${path}-${variant}`;
  return css`
    @font-face {
      font-family: ${family};
      font-style: ${style};
      font-display: swap;
      font-weight: ${weight};
      src: url('${fullPath}.woff2') format('woff2'), url('${fullPath}.woff') format('woff'),
        url('${fullPath}.otf') format('opentype'), url('${fullPath}.ttf') format('truetype');
    }
    * {
      font-feature-settings: 'ss05';
    }
  `;
}
