import { TpFFUI } from '@noah-labs/core-web-ui/src/types';
import { AccountType, AuthGroups, Feature } from '@noah-labs/shared-schema-gql';
import { appendToTitle } from '../../utils';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from './types';
import { getCurrenciesFromConfig } from './utils/getCurrenciesFromConfig';

// URL FORMAT
// /wallet/<CurrencyCode>/<AccountType>/<Action>/<Step>

/**
 * Ensure that only the routes we specify in our enums are available,
 * so that we know that if the route renders, it must be one of enums
 */

const currenciesFromConfig = getCurrenciesFromConfig();
const currencyCodes = currenciesFromConfig.join('|').toLowerCase();
const accountTypes = Object.keys(AccountType).join('|').toLowerCase();
const paymentStatuses = Object.keys(TpCheckoutPaymentStatus).join('|').toLowerCase();
const payoutStatuses = Object.keys(TpCheckoutPayoutStatus).join('|').toLowerCase();

export const transactionsSlug = 'transactions';

const base = '/wallet';
const accountBase = `${base}/:currencyCode(${currencyCodes})/:accountType(${accountTypes})`;
const receiveBase = `${accountBase}/receive`;
const lightningSendBase = `${accountBase}/lightning-send`;
const withdrawBase = `${accountBase}/withdraw`;
const depositBase = `${accountBase}/deposit`;
const buyBase = `${accountBase}/buy`;
const sellBase = `${accountBase}/sell`;
const transactionsBase = `${base}/${transactionsSlug}`;
const addressBase = `${accountBase}/address`;
const selectCryptoBase = `${base}/select-crypto`;

const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  account: {
    base: {
      groups,
      path: accountBase,
      title: (curr: string): string => appendToTitle(curr, 'account'),
    },
    transactions: {
      groups,
      path: `${accountBase}/${transactionsSlug}/:id`,
      title: 'Transaction',
    },
  },
  address: {
    base: {
      path: addressBase,
    },
    scan: {
      groups,
      path: `${addressBase}/scan`,
      title: 'Scan address',
    },
    text: {
      groups,
      path: `${addressBase}/text-entry`,
      title: 'Enter Address',
    },
  },
  base: {
    groups,
    path: base,
    title: 'Wallet',
  },
  buy: {
    base: {
      path: buyBase,
    },
    complete: {
      feature: Feature.CkoBuy,
      groups,
      path: `${buyBase}/complete/:paymentStatus(${paymentStatuses})`,
      title: 'Transaction Complete',
    },
    confirm: {
      feature: TpFFUI.CkoBuyRoutes,
      groups,
      path: `${buyBase}/confirm`,
      title: 'Buy Confirmation',
    },
    enterAmount: {
      feature: TpFFUI.CkoBuyRoutes,
      groups,
      path: buyBase,
      title: 'Buy',
    },
    moonpay: {
      feature: Feature.MoonpayBuy,
      groups,
      path: `${buyBase}/moonpay`,
      title: 'Buy with Moonpay',
    },
    moonpayComplete: {
      feature: Feature.MoonpayBuy,
      groups,
      path: `${buyBase}/moonpay/complete`,
    },
    payment: {
      feature: TpFFUI.CkoBuyRoutes,
      groups,
      path: `${buyBase}/payment`,
      title: 'Card Details',
    },
  },
  deposit: {
    base: {
      feature: Feature.Deposit,
      groups,
      path: depositBase,
      title: (curr: string): string => appendToTitle('Receive', curr),
    },
  },
  lightningSend: {
    base: {
      path: lightningSendBase,
    },
    complete: {
      feature: Feature.LnPayment,
      groups,
      path: `${lightningSendBase}/complete`,
      title: 'Payment Processing',
    },
    confirm: {
      feature: Feature.LnPayment,
      groups,
      path: `${lightningSendBase}/confirm`,
      title: 'Confirm Transaction',
    },
    enterAmount: {
      feature: Feature.LnPayment,
      groups,
      path: lightningSendBase,
      title: 'Enter Send Amount',
    },
    invoiceExpired: {
      groups,
      path: `${lightningSendBase}/invoice-expired`,
      title: 'Invoice Expired',
    },
    request: {
      feature: Feature.LnPayment,
      groups,
      path: `${lightningSendBase}/request/:source/:encodedSendParams`,
      title: 'Lightning Payment Request',
    },
  },
  receive: {
    base: {
      groups,
      path: receiveBase,
    },
    lightning: {
      enterAmount: {
        feature: Feature.LnInvoice,
        groups,
        path: `${receiveBase}/lightning`,
        title: 'Enter Request Amount',
      },
      viewInvoice: {
        feature: Feature.LnInvoice,
        groups,
        path: `${receiveBase}/lightning/view-invoice`,
        title: 'Invoice',
      },
    },
    network: {
      feature: TpFFUI.DepositRoutes,
      groups,
      path: `${receiveBase}/network`,
      title: 'Select Network',
    },
  },
  selectCrypto: {
    base: {
      path: selectCryptoBase,
    },
    receive: {
      groups,
      path: `${selectCryptoBase}/receive`,
      title: 'Receive',
    },
    send: {
      groups,
      path: `${selectCryptoBase}/send`,
      title: 'Send',
    },
  },
  sell: {
    base: {
      path: sellBase,
    },
    complete: {
      feature: Feature.CkoSell,
      groups,
      path: `${sellBase}/complete/:payoutStatus(${payoutStatuses})`,
      title: 'Transaction Complete',
    },
    confirm: {
      feature: TpFFUI.CkoSellRoutes,
      groups,
      path: `${sellBase}/confirm`,
      title: 'Sell Confirmation',
    },
    enterAccountDetails: {
      feature: Feature.ManualRampSell,
      groups,
      path: `${sellBase}/payment/bank`,
      title: 'Account details',
    },
    enterAmount: {
      feature: TpFFUI.CkoSellRoutes,
      groups,
      path: `${sellBase}/enter-amount`,
      title: 'Sell',
    },
    enterPaymentInfo: {
      feature: TpFFUI.CkoSellRoutes,
      groups,
      path: `${sellBase}/payment/card`,
      title: 'Card Details',
    },
    moonpay: {
      base: {
        feature: Feature.MoonpaySell,
        groups,
        path: `${sellBase}/moonpay`,
        title: 'Sell with Moonpay',
      },
      complete: {
        feature: Feature.MoonpaySell,
        groups,
        path: `${sellBase}/moonpay/complete`,
        title: 'Complete',
      },
      confirm: {
        feature: Feature.MoonpaySell,
        groups,
        path: `${sellBase}/moonpay/confirm`,
        title: 'Confirm',
      },
    },
    payoutMethod: {
      feature: TpFFUI.CkoSellRoutes,
      groups,
      path: sellBase,
      title: 'Sell',
    },
  },
  transactions: {
    base: {
      path: `${transactionsBase}/:id`,
    },
  },
  withdraw: {
    base: {
      path: withdrawBase,
    },
    complete: {
      feature: Feature.Withdraw,
      groups,
      path: `${withdrawBase}/complete`,
      title: 'Processing Withdrawal',
    },
    confirm: {
      feature: Feature.Withdraw,
      groups,
      path: `${withdrawBase}/confirm`,
      title: 'Confirm Transaction',
    },
    enterAmount: {
      feature: Feature.Withdraw,
      groups,
      path: withdrawBase,
      title: 'Enter Send Amount',
    },
    network: {
      feature: Feature.Withdraw,
      groups,
      path: `${withdrawBase}/network`,
      title: 'Select Network',
    },
  },
};
