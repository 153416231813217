import type { ContainerProps } from '@mui/material';
import { Container } from '@mui/material';

type PpSceneMain = ContainerProps & {
  dense?: boolean;
};
export function SceneMain({
  dense = false,
  maxWidth = 'sm',
  sx,
  ...rest
}: PpSceneMain): React.ReactElement {
  // we use padding to ensure any background colours are 'full bleed'
  const pt = dense ? 3 : 4;
  return (
    <Container
      component="section"
      maxWidth={maxWidth}
      sx={{ height: '100%', pt, ...sx }}
      {...rest}
    />
  );
}
