import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';

const { commitHash, datadog } = webConfigBrowser;

export function DatadogProvider({ children }: PpWC): React.ReactElement {
  const { data, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!datadog.enabled || !isAuthenticated || !data?.userId) {
      return;
    }

    datadogLogs.setUser({
      id: data.userId,
    });
  }, [isAuthenticated, data?.userId]);

  useEffect(() => {
    if (!datadog.enabled) {
      return;
    }

    datadogLogs.init({
      clientToken: datadog.token,
      env: webConfigBrowser.appStage.toLowerCase(),
      forwardErrorsToLogs: true,
      service: 'fe-web',
      sessionSampleRate: 100,
      version: commitHash,
    });
  }, []);

  return children as ReactElement;
}
