import type { UseQueryResult } from 'react-query';

/**
 * TpAuthStatus allows a simplified way of knowing whether a user is:
 *
 * - definitely authenticated
 * - definitely a guest
 * - in an unknown state
 */
export enum TpAuthStatus {
  unknown,
  guest,
  authenticated,
}

export type TpUseQueryResultReplacedData<T, N> = Omit<UseQueryResult<T>, 'data'> & {
  data: N | undefined;
};

export type TpAwsWafIntegration = {
  fetch: (url: '') => Promise<{ status: number }>;
};

export type TpAwsWafCaptcha = {
  renderCaptcha: (
    container: HTMLElement | null,
    params: { apiKey: string; onError: (error: unknown) => void; onSuccess: () => void }
  ) => void;
};
