import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { AxiosInstance } from 'axios';
import axios from 'axios';

/**
 * https://api-reference.checkout.com/
 */
class Cko {
  private publicKey: string | undefined;

  private axiosClient: AxiosInstance | undefined;

  configure(url: string, publicKey: string): void {
    this.publicKey = publicKey;
    this.axiosClient = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${this.publicKey}`,
        'Content-type': 'application/json',
      },
    });
  }

  get client(): AxiosInstance {
    if (!this.axiosClient) {
      return logger.fatal('checkoutApi has not been configured');
    }
    return this.axiosClient;
  }
}

export const ckoApi = new Cko();
