import { useContext } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { CxOry } from './OryContext';
// eslint-disable-next-line import/extensions
import { OryContext } from './OryContext';

export function useOry(): CxOry {
  const context = useContext(OryContext);
  if (context === undefined) {
    return logger.fatal('useOry must be used within an OryProvider');
  }
  return context;
}
