import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { AxiosInstance } from 'axios';
import axios from 'axios';

type InputConfigure = {
  publishableKey: string;
  url: string;
};

export class Moonpay {
  private publishableKey: string | undefined;

  private axiosClient: AxiosInstance | undefined;

  private url: string | undefined;

  configure({ publishableKey, url }: InputConfigure): void {
    this.publishableKey = publishableKey;
    this.url = url;
    this.axiosClient = axios.create({
      baseURL: 'https://api.moonpay.com/',
      params: {
        apiKey: this.publishableKey,
      },
      timeout: 3000,
    });
  }

  getPublishableKey(): string {
    if (!this.publishableKey) {
      return logger.fatal('moonpayPublishableKey has not been configured');
    }
    return this.publishableKey;
  }

  client(): AxiosInstance {
    if (!this.axiosClient) {
      return logger.fatal('moonpayApi has not been configured');
    }
    return this.axiosClient;
  }

  moonpayDomain(type: 'buy' | 'sell'): string {
    return `https://${type}${this.url || ''}`;
  }
}

export const moonpayApi = new Moonpay();

export function moonpayTxReceiptUrl(
  type: 'buy' | 'sell',
  transactionId: string | undefined
): string | undefined {
  return (
    transactionId &&
    `https://${type}.moonpay.com/transaction_receipt?transactionId=${transactionId}`
  );
}
