import { forwardRef } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Link as RouterLink } from 'react-router-dom';
import { isExternalLink } from './isExternalLink';

type PpLinkBehavior = Omit<RouterLinkProps, 'to'> & {
  children: React.ReactNode;
  href: RouterLinkProps['to'];
};

// See: https://next.material-ui.com/guides/routing/#global-theme-link
export const LinkBehavior = forwardRef<HTMLAnchorElement, PpLinkBehavior>(
  ({ children, href, ...rest }, ref) =>
    isExternalLink(href) ? (
      <a ref={ref} href={href} rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    ) : (
      <RouterLink ref={ref} to={href} {...rest}>
        {children}
      </RouterLink>
    )
);

LinkBehavior.displayName = LinkBehavior.name;
