import { useCallback, useMemo } from 'react';
import { disableRefetchRetry } from '@noah-labs/core-services';
import type { TpFeatureFlagUI, TpFeatureUI } from '@noah-labs/core-web-ui/src/types';
import type { UseQueryResult } from 'react-query';
import { useQueryClient } from 'react-query';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { getUIFeatures } from './getUIFeatures';
import type { UserInitQuery } from './user.generated';
// eslint-disable-next-line no-restricted-imports
import { useUserInitQuery } from './user.generated';

const queryKey = ['UserInit'];

export type TpUserSettingsConsentQuery = UserInitQuery['userSettingsConsent'][0];
export type TpUseUserInitQueryResults = UseQueryResult<UserInitQuery, unknown> & {
  features: Map<TpFeatureUI, TpFeatureFlagUI> | undefined;
  featuresString: string;
  invalidate: () => Promise<void>;
  lnAddress?: string;
  userSettingsConsent: TpUserSettingsConsentQuery | undefined | null;
};
export function useUserInit(): TpUseUserInitQueryResults {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const userInitResponse = useUserInitQuery(undefined, {
    enabled: auth.isAuthenticated,
    ...disableRefetchRetry,
    select: (data) => ({
      ...data,
      userProfile: {
        ...data.userProfile,
        /**
          We decided to have FiatPaymentCurrencyCode as optional,
          that way we need to default to FiatCurrencyCode
        */
        FiatPaymentCurrencyCode:
          data.userProfile.FiatPaymentCurrencyCode || data.userProfile.FiatCurrencyCode,
      },
      userSettingsConsent: data.userSettingsConsent,
    }),
  });

  const { FeatureFlags, KycVerification } = userInitResponse.data?.userProfile || {};

  const [features, featuresString] = useMemo(() => {
    if (!userInitResponse.isFetched) {
      // user is not fetched, features are unknown, return undefined
      return [undefined, ''];
    }

    const fm = getUIFeatures({ FeatureFlags, KycVerification });
    const fs = Array.from(fm)
      .filter(([_, ff]) => ff.Enabled)
      .map(([f]) => f)
      .join(', ');

    return [fm, fs];
  }, [FeatureFlags, KycVerification, userInitResponse.isFetched]);

  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries(queryKey);
  }, [queryClient]);

  const lnAddress = userInitResponse.data?.userProfile.UsernameDisplay
    ? `${userInitResponse.data.userProfile.UsernameDisplay}@${webConfigBrowser.lnDomain}`
    : undefined;

  return {
    ...userInitResponse,
    features,
    featuresString,
    invalidate,
    lnAddress,
    userSettingsConsent: userInitResponse.data?.userSettingsConsent[0],
  };
}
